.playlist-card {
  max-height: 22rem;
  max-width: 16rem;
  min-height: 22rem;
  min-width: 16rem;
  border: none;
  border-radius: 2.5%;
  background-color: #181818;
  transition: 200ms;
}

.playlist-card:hover {
  background-color: #282828;
  transition: 200ms;
  cursor: pointer;
}

.card-img {
  width: 13.125rem;
  height: 13.125rem;
  border-radius: 0%;
  box-shadow: rgba(0, 0, 0, 0.19) 0rem 0.625rem 1.25rem, rgba(0, 0, 0, 0.23) 0rem 0.375rem 0.375rem;
}

a:focus .playlist-card {
  background-color: #282828;
  transition: 200ms;
}

