.navbar {
  background-color: #121212;
  color: white;
}

.navbar-brand {
  font-size: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #949494;
  transition: 150ms ease-in-out;
}

.navbar-dark .navbar-nav .nav-link.active:hover {
  color: #b0b0b0;
  transition: 150ms ease-in-out;
}

.navbar-brand:focus .white-to-green {
  color: #1ed760;
  transition: 200ms;
}

.navbar-brand:hover .white-to-green {
  color: #1ed760;
  transition: 200ms;
  text-decoration: none;
}

.fab,
.fas {
  font-size: 1.5rem;
}