@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Medium"), local("CircularStd-Medium"), url("fonts/CircularStd-Medium.woff2") format("woff2"), url("fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Book"), local("CircularStd-Book"), url("fonts/CircularStd-Book.woff2") format("woff2"), url("fonts/CircularStd-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Medium Italic"), local("CircularStd-MediumItalic"), url("fonts/CircularStd-MediumItalic.woff2") format("woff2"), url("fonts/CircularStd-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Black"), local("CircularStd-Black"), url("fonts/CircularStd-Black.woff2") format("woff2"), url("fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Bold"), local("CircularStd-Bold"), url("fonts/CircularStd-Bold.woff2") format("woff2"), url("fonts/CircularStd-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Bold Italic"), local("CircularStd-BoldItalic"), url("fonts/CircularStd-BoldItalic.woff2") format("woff2"), url("fonts/CircularStd-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Book Italic"), local("CircularStd-BookItalic"), url("fonts/CircularStd-BookItalic.woff2") format("woff2"), url("fonts/CircularStd-BookItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Black Italic"), local("CircularStd-BlackItalic"), url("fonts/CircularStd-BlackItalic.woff2") format("woff2"), url("fonts/CircularStd-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

body {
  background-color: #121212;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Circular Std";
  font-weight: 400;
  color: white;
  letter-spacing: 0.0125rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Circular Std";
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -0.0125rem;
}

.fullscreen-container {
  height: 100vh;
}

.bold-title {
  font-weight: 700;
}

.white-to-green {
  color: white;
  transition: 200ms;
  text-decoration: none;
}

.white-to-green:focus {
  color: #1ed760;
  transition: 200ms;
}

.white-to-green:visited {
  color: white;
  transition: 200ms;
  text-decoration: none;
}

.white-to-green:hover {
  color: #1ed760;
  transition: 200ms;
  text-decoration: none;
}

.body {
  margin-top: 3.9375rem;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gray-text {
  color: #bebdb9;
}

.description-text {
  max-width: 34.375rem;
}

a {
  text-decoration: none;
  border-radius: 2.5%;
}