.button {
  border: solid;
  border-width: 0.0625rem;
  font-family: "Circular Std";
  font-weight: 700;
}

.button-color {
  background-color: #1ed760;
  border-color: #1ed760;
  color: black;
  transition: 300ms;
}

.button-color:hover {
  background-color: #1fdf64;
  border-color: #1fdf64;
  color: black;
  transition: 300ms;
}

.button-color:focus {
  color: black;
  background-color: #169c46;
  border-color: #169c46;
  outline: solid 0.1875rem #169c46;
  outline-offset: 0.1875rem;
}

.button-color:disabled {
  color: black;
  background-color: #1ed760;
  border-color: #1ed760;
}

.button-outline {
  background-color: transparent;
  border-color: white;
  color: white;
  transition: 300ms;
}

.button-outline:hover {
  background-color: white;
  border-color: white;
  color: black;
  transition: 300ms;
}

.button-outline:focus {
  background-color: white;
  border-color: white;
  color: black;
  outline: solid 0.1875rem white;
  outline-offset: 0.1875rem;
}

.button-outline:disabled {
  background-color: transparent;
  border-color: white;
  color: white;
}

.button-delete {
  background-color: #eb1d31;
  border-color: #eb1d31;
  color: white;
  transition: 300ms;
}

.button-delete:hover {
  background-color: #e64454;
  border-color: #e64454;
  transition: 300ms;
}

.button-delete:focus {
  background-color: #ca1a2a;
  border-color: #ca1a2a;
  outline: solid 0.1875rem #ca1a2a;
  outline-offset: 0.1875rem;
}

@media only screen and (min-width: 48rem) and (max-width: 50.9375rem) {
  .delete {
    margin-top: 1rem;
  }
}

.stretch {
  width: 12rem;
}