.artist-names {
  font-size: 0.9rem;
  color: #b3b3b3;
}

.track-image {
  margin-right: 0.9375rem;
}

.playlist-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.playlist-image {
  box-shadow: rgba(0, 0, 0, 0.19) 0rem 0.625rem 1.25rem, rgba(0, 0, 0, 0.23) 0rem 0.375rem 0.375rem;
}

@media (min-width: 48rem) {
  .affix {
    position: fixed;
  }

  .playlist-title {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.label-text {
  letter-spacing: 0.1rem;
  font-family: "Circular Std";
  font-weight: 400;
  font-size: 0.8rem;
  color: #bebdb9;
}
